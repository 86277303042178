@use "sass:math";

.merge-page {
    .hover_style {
        &:hover {
            cursor: pointer;
        }
    }

    .target_user li a {
        color: black;
    }

    .row {
        .valid {
            color: $yellow;
        }

        .invalid {
            color: gray;
        }

        .row_delete {
            &:hover {
                cursor: pointer;
            }
        }

        .type-inputs {
            .fa-square, .fa-check-square {
                color: $yellow;
            }
        }
    }

    u i {
        font-style: italic;
        cursor: pointer;
    }
}
.move-data-arrow::before {
    cursor: pointer;
    cursor: hand;
    //color: $mediclient_blue;
    font-weight: bold;
}

move-data-arrow {
    display: none;
}
.merge-table .col-user-secondary .move-data-arrow {
    display: auto;
}

.merge-email-row {
    width: 100%;
}

.move-data-arrow {
    display: none;
}
.active-merge-columns .move-data-arrow {
    display: inline-block; 
}

.row-user {
    justify-content: center;
}

.merge-table {
    $breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @for $i from 1 through 12 {
            .merge-col#{$infix}-#{$i} {
                flex: 0 0 percentage(math.div($i, 12));
                max-width: percentage(math.div($i, 12));
            }
        }
    }

    h3 {
        margin-top: 25px;
        padding-bottom: 3px;
    }

    .col-user {
        max-width: 700px;
        //border-left: 1px solid $mediclient_dark_gray;
        //border-right: 1px solid $mediclient_dark_gray;
        //background: $mediclient_gray;
    }

    .merge-hidden {
        display: none;
    }

    .col-user-target {
        .merge-target-hidden {
            display: none;
        }

        .move-data-arrow {
            display: none;
        }
    }

    .move-data-arrow-up:before {
        bottom: 4px;
        position: relative;
    }

    .col-user-secondary .merge-secondary-hidden {
        display: none;
    }

    .col-user-secondary {

        .ajax-container[data-attribute=UserProfession], .ajax-container[data-attribute=UserEmail] {
            .custom-control-label::after, .custom-control-label::before {
                margin-left: 14px;
                margin-right: 14px;
            }
        }

        span.licence_header_span .licence_header:hover {
            cursor: auto;
        }

        .edit-icons, .save-icons {
            display: none;
        }

        .ajax-container[data-attribute=UserHistory] .edit-icons {
            display: block !important;

            .approved-icon {
                display: none;
            }
        }

        .ajax-container[data-attribute=UserEmail] form .edit-icons {
            display: block;
        }
    }

    .white_licence_box {
        margin-left: 0;
        margin-right: 0;
        max-width: 100% !important;
    }
}
.col-user-target .fa-pencil-alt {
    &:hover {
        cursor: pointer;
        color: $yellow;
    }
}