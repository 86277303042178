/// <reference path="../vendor/sb-admin/_variables.scss" />

.sidebar {
    background-color: $gray-800 !important;

    .sidebar-brand {
        padding: 1rem;

        .logo-image {
            height: 100%;
        }
    }

    .nav-item {
        .nav-link {
            color: $white;

            i {
                color: $white;
            }

            &:hover {
                color: $yellow;

                i {
                    color: $yellow;
                }
            }
        }
    }
}
