@import "layout/_reset.scss";

@import "vendor/sb-admin/_admin.scss";

@import "partials/_container.scss";
@import "partials/_sidebar.scss";

@import "elements/_button.scss";
@import "elements/_indicator.scss";
@import "elements/_loading.scss";
@import "elements/_form.scss";
@import "elements/_confirmation-box.scss";
@import "elements/_email_templater.scss";
@import "elements/_modals.scss";
@import "elements/_table.scss";
@import "elements/taginput.scss";
@import "elements/_info_bar.scss";

@import "pages/_search.scss";
@import "pages/_profile.scss";
@import "pages/_merge.scss";
@import "pages/_inactiveuser.scss";


.message_box .close_button {
    width: auto;
}