.inactive-users {
    .UserUnderDelete {
        font-weight: bold;
    }

    .UserDeleted {
        color: #ccc;
    }

    .UserDeleteError {
        color: #f00;
    }
}