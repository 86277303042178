#info-bar {
    position: fixed;
    bottom: 5px;
    right: 5px;
    overflow: hidden;
    z-index: 99999;
    background-color: transparent;
    transition-duration: 5s;
    min-width: 20px;

    .notification {
        border: 2px solid #aaaaaa;
        background-color: #ffffff;
        margin: 3px 0 3px 0;
        padding: 4px;
        max-width: 200px;
        border-radius: 3px;
        text-align: right;
        opacity: 0.8;
        font-weight: bold;
        color: #444444;
        font-size: 10px;

        &.deactivated {
            background-color: #aaaaaa;
            animation-delay: 0s;
            webkit-animation: 2s linear 0s archiving;
            animation: 2s linear 0s archiving;
        }
    }

    .archive-notifications {
        display: none;
        max-height: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        transition-property: max-height;
        transition-duration: 1s;
        transition-timing-function: linear;

        .notification {
            background-color: #aaaaaa;
        }
    }

    &:hover {
        .archive-notifications {
            display: block;
            max-height: 600px;
        }
    }

    .info-bar-footer {
        width: 100%;
        height: 10px;
        background-color: #aaaaaa;
    }
}


@-webkit-keyframes archiving {
    from {
        background-color: #ffffff;
    }

    to {
        background-color: #aaaaaa;
    }
}

@keyframes archiving {
    from {
        background-color: #ffffff;
    }

    to {
        background-color: #aaaaaa;
    }
}

//TODO kis méretben eltűntetni