.mailer-modal {
    .contents {
        list-style: disc;

        span {
            color: #4e73df;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .email-name {
        color: black;
    }

    .content {
        label {
            margin-bottom: 0;
        }

        input {
            color: black;
        }
    }
}

























#email-templater {

    .modal-body .col-2, .modal-body .col-3, .modal-body .col-4, .modal-body .col-5, .modal-body .col-6, .modal-body .col-7, .modal-body .col-8 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .category-wrapper {
        .sub-category {
            display: inline-block;
        }
    }

    .preview-wrapper {
        textarea {
            width: 100%;
        }
    }

    input {
        width: 100%;
    }

    #email-template-raw-textarea, #email-template-raw-textarea {
        height: 500px;
    }


    .property-list {
        height: 600px;
        overflow: scroll;
        padding: 0;

        li {
            cursor: pointer;
            list-style-type: none;
        }
    }
}
