﻿.status {
    &::after {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        content: "";
        margin-left: 10px;
        border-radius: 8px;
    }

    &.status_user_1::after { //aktív
        background-color: #66cc00;
    }

    &.status_user_2::after { // megerősítésre vár
        background-color: #fec23e;
    }

    &.status_user_3::after { // inakítv
        background-color: #ff1744;
    }

    &.status_user_4::after { // crm
        border: 3px solid #66cc00;
    }

    &.status_user_5::after { // hiánypótlásra vár
        border: 3px solid #ff1744;
    }

    &.status_user_6::after { // adatkiegészítésre vár
        border: 3px solid #fec23e;
    }
}
