.confirmation_box {
    display: none;
    position: absolute;
    background: white;
    border: 1px solid rgba(0,0,0,.2);
    z-index: 1000;
    width: 175px;
    font-size: 14px;
    border-radius: .3rem;
    top: -145px;
    left: -20px;
    padding: 10px;

    &.show {
        display: block !important;
    }

    h3 {
        background: #fff;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 1px solid #323232;
        font-size: 18px;

        &:before {
            content: "\f2ed";
            font-family: 'Font Awesome 5 Free';
            font-weight: bold;
            color: #c30201;
            margin-right: 5px;
        }
    }

    p {
        margin-bottom: 10px;
        font-weight: 500;
    }

    .btn-group .btn + .btn {
        margin-left: 15px !important;
    }
}

.confirmation_container {
    position: relative;
}
