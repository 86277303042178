/// <reference path="../vendor/sb-admin/_variables.scss" />

.search-container {
    .link_grey, .link_yellow {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .search_grey_box {
        background: $gray-200;
        padding: 15px;

        .form-check {
            input {
                margin-right: 5px;
            }
        }
    }

    label {
        margin-bottom: 0;
    }

    .table_sort {
        padding-top: 5px;

        p {
            padding: 5px;
        }

        select {
            -moz-appearance: none;
            width: 150px;
        }
    }

    .merge_button {
        padding-top: 5px;

        .part-of-merge-tool {
            width: 300px;
        }
    }

    .data-table {
        padding-top: 15px;

        thead {
            th {
                border-top: none;
            }
        }

        tbody {
            tr {
                td {
                    a {
                        color: $gray-600;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    img {
                        &.approved_icon {
                            width: 18px;
                        }
                    }
                }

                &:hover {
                    background: $gray-300;
                    cursor: pointer;
                }
            }
        }
    }

    .dataTables_info {
        padding-top: 10px;
    }

    .dataTables_paginate {
        margin: 0;
        white-space: nowrap;
        text-align: right;

        .pagination {
            white-space: nowrap;
            justify-content: flex-end;
        }
    }
}
