/// <reference path="../vendor/sb-admin/_variables.scss" />
.email-button {
    padding-top: 8px;

    i {
        color: #006600;

        &.angry {
            color: #db9846;
        }
    }

    &:hover {
        cursor: pointer;
    }
}


.profile {
    .profile-header {
        img {
            width: 25px;
            height: 25px;
        }
    }

    .email-button {
        padding-top: 8px;

        i {
            color: #006600;

            &.angry {
                color: #db9846;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .status-switchers {
        .d-flex {
            margin-right: 25px;
        }

        .email-button {
            padding-right: 5px;
            padding-left: 5px;
        }

        .detail_item {
            margin-right: 15px;

            #validation-name {
                width: 25px;
                background: black;
            }
        }

        .status_button {
            text-transform: uppercase;
            background: white;

            &:hover, &:active, &:focus {
                background: white;
            }

            &.status_1, &.valstatus_1 { //aktív //validált
                border: 2px solid #66cc00;
                color: #66cc00;
            }

            &.status_2, &.valstatus_3 { // megerősítésre vár // nem szükséges
                border: 2px solid #fec23e;
                color: #86827e;
            }

            &.status_3 { // inakítv
                border: 2px solid #ff1744;
                color: #ff1744;
            }

            &.status_4 { // crm
                border: 2px solid #66cc00;
                color: #86827e
            }

            &.status_5, &.valstatus_2 { // hiánypótlásra vár //nem validált
                border: 2px solid #ff1744;
                color: #86827e
            }

            &.status_6 { // adatkiegészítésre vár
                border: 2px solid #fec23e;
                color: #fec23e
            }
        }

        .licence-number {
            width: 100px;
        }

        .validation-name {
            width: 325px;
        }
    }

    .editable {
        &:hover {
            cursor: pointer;
            padding-right: 0 !important;
            background: $blue !important;
            color: white;
            padding: 0px 10px 0 10px;
            display: inline-block;
            border-radius: 5px;
            position: relative;
            left: -10px;
            margin-right: -5px;

            &::after {
                content: "\f303";
                font-family: "Font Awesome 5 Free";
                font-size: 14px;
                padding-left: 4px;
                top: -1px;
                position: relative;
                font-weight: 600;
                padding-right: 10px;
            }
        }
    }

    .copyable {
        &:hover {
            cursor: pointer;
        }
    }

    .add_icon {
        position: relative;
        top: -2px;
        margin-right: 5px;

        &:hover {
            cursor: pointer;
        }
    }

    .actions {
        display: flex;

        .fas {
            padding-left: 5px;
        }

        i:hover {
            color: $yellow;
            cursor: pointer;

            &.fa-trash-alt {
                color: $red;
            }
        }
    }

    .row {
        .border-container {
            border-bottom: 1px solid rgba(0,0,0,.2);
            margin-bottom: 9px;
            padding-bottom: 3px;
        }

        &:last-child {
            .border-container {
                border: none;
            }
        }
    }

    .card {
        .card-header {
            h6 {
                i {
                    margin-right: 5px;
                }
            }

            .dropdown {
                .dropdown-item {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &.py-3 {
                padding-top: .5rem !important;
                padding-bottom: .5rem !important;
            }
        }

        .card-body {
            .gray_label {
                display: block;
                font-size: .7rem;
                font-weight: 700 !important;
            }
        }
    }

    #profile-main {
        .properties {
            label + label {
                margin-left: 10px;
            }
        }
    }

    #profile-meta {
        hr {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    #profile-email {
        .row {
            .border-container {
                border-bottom: 1px solid rgba(0,0,0,.2);
                margin-bottom: 9px;
                padding-bottom: 3px;
            }

            &:last-child {
                .border-container {
                    border: none;
                }
            }

            &.primary .address {
                font-weight: bold;
            }

            .fa-trash-alt {
                margin-right: 5px;
                padding-top: 5px;

                &:hover {
                    color: $red;
                    cursor: pointer;
                }
            }

            .primary_button {
                margin-right: 5px;

                button {
                    padding: 0 10px;
                }
            }

            .afl {
                font-size: 18px;
                margin-right: 15px;

                .valid {
                    color: #66cc00;
                }

                .invalid {
                    color: #ff1744;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .address {
                input {
                    min-width: 250px;
                }

                .input-ok {
                    right: 7px;
                    position: relative;
                }

                .input-cancel {
                    position: relative;
                    right: 9px;
                }
            }

            .rights {
                display: flex;
                flex-direction: row;
                position: relative;
                flex-wrap: wrap;

                .simple-sets {
                    flex-shrink: 0;
                    margin-right: 5px;
                    margin-top: 5px;

                    .simple-set {
                        color: $yellow;
                        font-weight: bold;
                        font-size: 13px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    width: 63px;
                    text-align: center;
                    margin-right: 5px;

                    span {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 11px;
                    }

                    .small2 {
                        margin-top: 2px;
                        font-size: 10px;
                    }

                    .small {
                        margin-top: 2px;
                        font-size: 12px;
                        padding: 1px 2px;
                    }

                    .btn {
                        margin-top: 2px;
                        height: 22px;
                        padding: 0;
                    }
                }

                .bounce {
                    display: flex;
                    flex-direction: column;
                    width: 170px;

                    span {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 11px;
                    }

                    select {
                        margin-top: 2px;
                        height: 24px;
                        padding: 1px 18px 1px 5px;
                        background: url(/images/arrow-down-icon.png) no-repeat right 3px top 0px !important;
                    }
                }
            }
        }
    }

    #profile-profession {
        .row {
            .border-container {
                border-bottom: 1px solid rgba(0,0,0,.2);
            }

            &:last-child {
                .border-container {
                    border: none;
                }
            }

            .fa-trash-alt {
                padding-top: 5px;
                margin-right: 5px;

                &:hover {
                    color: $red;
                    cursor: pointer;
                }
            }

            .work_active {
                font-size: 18px;
                margin-right: 7px;

                .valid {
                    color: $yellow;
                }
                /*
            .invalid {
                color: $mediclient_medium_gray;
            }
*/
                &:hover {
                    cursor: pointer;
                }
            }

            p {
                margin-right: 10px;
                margin-bottom: 5px;
            }

            .blazored-typeahead {
                height: 30px;
            }

            .blazored-typeahead__input-mask {
                display: inline-block;
                height: 30px;
                overflow: hidden;
                padding: 2px 10px;
            }

            .blazored-typeahead__clear {
                padding: 0;
            }

            .fa-save {
                padding-top: 5px;
                padding-left: 10px;

                &:hover {
                    cursor: pointer;
                    color: $yellow;
                }
            }

            .blazored-typeahead__input {
                padding: 0;
            }

            .red {
                color: #f45c41;
            }
        }
    }

    #profile-address {
        .row {
            .border-container {
                border-bottom: 1px solid rgba(0,0,0,.2);
            }

            &:last-child {
                .border-container {
                    border: none;
                }
            }

            .actions {
                margin-right: 15px;
            }

            .first_section {
                margin-right: 15px;
                width: 160px;

                i + span {
                    margin-right: 11px;
                    margin-left: 2px;
                    text-transform: uppercase;
                    color: #323232;
                    font-weight: bold;
                    font-size: 12px;
                }

                i {
                    color: $yellow;

                    &:hover {
                        cursor: pointer;
                    }

                    &.fa-trash-alt {
                        &:hover {
                            color: $red;
                        }
                    }
                }
            }

            .second_section {
                position: relative;
                padding-right: 45px;

                abbr {
                    color: #ff1744;
                    position: absolute;
                    top: 1px;
                    right: 10px;
                }
            }

            .blazored-typeahead__input {
                padding: 0;
            }
        }
    }

    #profile-subscription {
        .row {
            .border-container {
                border-bottom: 1px solid rgba(0,0,0,.2);
            }

            &:last-child {
                .border-container {
                    border: none;
                }
            }

            .first-row {
                margin-right: 15px;

                label {
                    width: 70px;
                }

                select {
                    width: 300px;
                }

                .error-message {
                    color: $red;
                    text-align: right;
                    font-weight: bold;
                    margin-bottom: 0;
                    font-style: italic;
                }
            }

            .second-row {
                margin-right: 15px;

                label {
                    width: 100px;
                }

                input {
                    width: 140px;
                }

                &.valid {
                    .cdate {
                        background: #42f495 !important;
                    }
                }

                &.invalid {
                    .cdate {
                        background: #f45c41 !important;
                    }
                }
            }

            .third-row {
                margin-right: 10px;

                textarea {
                    width: 100%;
                }
            }

            .licence_row {
                display: flex;

                .actions {
                    display: flex;
                    margin-right: 15px;
                    padding-top: 4px;

                    i + i {
                        padding-left: 5px;
                    }
                }

                .licence_cont {
                    .start-date, .coupon-code {
                        margin-left: 25px;
                    }

                    .remark {
                        margin-left: 50px;
                    }

                    .valid {
                        .cdate {
                            background: #42f495 !important;
                        }
                    }

                    .invalid {
                        .cdate {
                            background: #f45c41 !important;
                        }
                    }
                }
            }

            .devices {
                margin-left: 30px;

                i:hover {
                    color: $yellow;
                    cursor: pointer;

                    &.fa-trash-alt {
                        color: $red;
                    }
                }

                p {
                    margin-bottom: 0;

                    & + p {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    #profile-courses {
        .course-row {
            //display: flex;

            &:last-child .border-container {
                border: none;
            }

            .actions {
                display: flex;
                margin-right: 15px;
                padding-top: 4px;

                .fa, .fas {
                    padding-left: 5px;
                }

                i {
                    color: #858796;

                    &:hover {
                        color: $yellow;
                        cursor: pointer;
                    }

                    &.fa-trash-alt {
                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    #profile-task {
        .row {
            small {
                font-size: 80%;
                font-weight: 400;

                i {
                    font-style: italic;
                }
            }

            textarea {
                width: 100%;
            }
        }
    }

    #profile-property {
        .properties {
            label + label {
                margin-left: 10px;
            }
        }
    }

    #profile-activity {
        font-size: 15px;

        .card-body {
            overflow-x: auto;

            i {
                font-size: 18px;
                margin-left: 7px;
                color: $yellow;

                &:hover {
                    cursor: pointer;
                }

                &.fa-trash-alt {
                    &:hover {
                        color: $red;
                    }
                }

                & + span {
                    margin-left: 2px;
                    color: #323232;
                    font-size: 12px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .status-switchers {
        .d-flex {
            margin-bottom: 10px;
        }
    }
}


//TODO email ikon lg alatt ne legyen