.custom-input-wrapper {
    .custom-input {
        padding-right: 30px;
    }

    .input-ok {
        position: relative;
        right: 36px;
        padding: 0;
    }

    .input-cancel {
        position: relative;
        right: 36px;
        padding: 0;
    }

    i {
        font-size: 14px;
    }
}

.input-ok {
    padding: 0 5px;

    &:hover {
        cursor: pointer;
        color: #66cc00
    }
}

.input-cancel {
    padding: 0 5px;

    &:hover {
        cursor: pointer;
        color: #ff1744
    }
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #6e707e;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid #d1d3e2;
    border-radius: .35rem;
    appearance: none;
}

input:disabled, select:disabled {
    border: 1px solid rgba(118, 118, 118, 0.3);
}

.validation-message {
    color: red;
}