/// <reference path="../variables/_color.scss" />
/// <reference path="../variables/_font.scss" />
/// <reference path="../variables/_size.scss" />
/// <reference path="../layout/bootstrap/v4.5.2/mixins/_breakpoints.scss" />

.taginput {
    .taginput-container {
        border: 1px solid #ced4da;
        padding: 5px 5px 2px 5px;
        height: auto;
        border-radius: .25rem;

        .addtag-container {
            .addtag {
                padding: 0 !important;
            }
        }

        .selected-tag {
            padding: 2px 5px;
        }
    }

    &.form-group {
        margin-bottom: 0;
    }

    .searchresult-container {
        .dropdown-item {
            color: #000;
            background: #fff;

            &.active {
                color: #000;
                background: #bbb;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.autocomplete, .taginput {
    position: relative;

    .searchresult-container {
        max-height: 300px;
        overflow: auto;
        position: absolute;
        background: white;
        /*width: 100%;*/
        box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
        z-index: 1000;

        .dropdown-item {
            color: #000;
            background: #fff;

            .active {
                color: #000;
                background: #bbb;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}