// Background Gradient Utilities

@each $color, $value in $theme-colors {
  .bg-gradient-#{$color} {
    background-color: $value;
    background-size: cover;
  }
}

// Grayscale Background Utilities

@each $level, $value in $grays {
  .bg-gray-#{$level} {
    background-color: $value !important;
  }
}
