/// <reference path="../vendor/sb-admin/_variables.scss" />

#content {
    .navbar {
        &.topbar {
            #sidebarToggleTop {
                .fa-bars {
                    color: $yellow;
                }

                &:active, &:focus {
                    background: rgba($yellow, 0.4);
                }
            }
        }

        .profile-icon {
            font-size: 24px;
        }
    }
}
