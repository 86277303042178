/// <reference path="../vendor/sb-admin/_variables.scss" />

.btn {
    &.btn-primary {
        background-color: $yellow !important;
        border-color: $yellow !important;
        color: $white;

        &:hover {
            background-color: $yellow-dark !important;
            border-color: $yellow-dark !important;
        }

        &:focus, &:active:focus {
            box-shadow: none !important;
        }
    }

    &.dropdown-toggle {
        background: #efefef;
    }

    &.btn-danger, &.btn-secondary {
        color: $white;
    }


}

.paginate_button {
    cursor: pointer;
}

.cursor-hand {
    cursor: pointer;
    cursor: hand;
}

.button-before-space {
    padding-left: 7px;
    padding-right: 7px;
}