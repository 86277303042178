table {
    tr {
        td {
            padding: 0 !important;

            a {
                padding: .75rem .75rem 2.4rem .75rem;
                display: block;
                text-decoration: none;

                &.ids {
                    padding-bottom: .75rem !important;
                }
            }
        }
    }

    &.no-link {
        td {
            padding: .75rem !important;
        }
    }
}


/*table.data-table {
    thead {
        margin-top: 10px;

        th {
            border-right: 3px solid #fff;
        }
    }

    tbody {
        tr {
            td {
                vertical-align:middle;
            }
        }
    }

    .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
        background-color: $mediclient_medium_gray;
        color: #fff;
    }

    .clickable-row {
        cursor: pointer;
        &:hover {
            background-color: $mediclient_medium_gray;
                 }
    }

    .table_sort {
        .custom-select {
            height: 28px !important;
            border-radius: 0;
            font-size: 16px;
            font-weight: 400;
            width: 25%;
            padding-top: 1px;
            color: $mediclient_dark_gray;
        }
    }
}

.email-table{
    select:disabled{
        background: transparent !important;
        border: none !important;
        width: auto !important;
        padding: 0;
    }
}



.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}*/