.nmodal {
    .nmodal-window {
        max-height: 90%;
    }

    .nmodal-header {
        background: white !important;
        border-bottom: 1px solid #eaecf4 !important;

        .nmodal-title {
            text-transform: uppercase;
        }

        button {
            border: none;
            background: none;
        }
    }

    select {
        display: block;
        height: 30px;
        position: relative;
        //top: -1px; username edit modal miatt
    }

    .TimeToSend select {
        display: inline-block;
    }

    .form-group {
        select {
            width: 100%;
            padding: 3px 10px !important;
            height: auto !important;
        }

        label {
            margin-bottom: 2px;
            margin-right: 20px;
        }

        .form-control {
            height: 30px;
        }

        &.less-margin {
            margin-bottom: 5px;
        }
    }

    button[type="submit"] {
        margin-top: 10px;
    }

    .name-edit-modal {
        select, input {
            width: 180px;
            margin-top: 10px;
        }
    }

    .new-user-modal {
        .row {
            margin-bottom: 10px;

            &.double_input_inline {
                select, input {
                    width: 100%;
                }
            }
        }
    }

    .address-modal {
        /*label {
            color: $medium_gray;
        }*/
        select, input {
            color: black;
        }

        .blazored-typeahead {
            height: 30px;
        }

        .blazored-typeahead__input-mask {
            display: inline-block;
            height: 30px;
            overflow: hidden;
            padding: 2px 10px;
        }

        .blazored-typeahead__clear {
            padding: 0;
        }

        .fa-save {
            padding-top: 5px;
            padding-left: 10px;

            &:hover {
                cursor: pointer;
                color: $yellow;
            }
        }

        .blazored-typeahead__input {
            padding: 0;
        }
    }
}

.modal-dialog {
    max-width: 600px;
}

.modal-title {
    text-transform: uppercase;
}

.close_label {
    font-weight: 400;
    font-size: 16px;
}

.own_modal_footer {
    display: flex;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #e9ecef;
}

.nmodal-body {
    padding-right: 20px;
    padding-left: 20px;

    .col-1 {
        padding: 4px;
        width: 6%;

        input {
            padding: 2px;
        }
    }
}

#newsletter_modal{
    max-width: 100%;
    .modal-content{
        width: 100%;
    }
}

.wide-modal {
    .modal-dialog {
        width: 90%;
        max-width: none;
        margin: 1.75rem 5%;

        .modal-content {
            width: 100%;
        }
    }
}

.draggable-modal {
    -webkit-box-shadow: 3px 3px 78px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 78px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 78px 0px rgba(0,0,0,0.75);
    width: 50%;
    max-width: none;
    margin: 1.75rem 5%;
    z-index: 65500;
    position: absolute;
    left: 25%;
    top: 80px;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }

    .modal-content {
        width: 100%;
    }
}

.wide-draggable-modal {
    width: 82%;
    left: 4%;
}

#errorModal {
    z-index: 65600;
}

@include media-breakpoint-down(sm) {
    .nmodal {
        .name-edit-modal {
            select, input {
                width: 100%;
            }
        }
    }
}